// Migrated
<template>
  <span class="inline-block">
    <template v-if="$isSol">
      <component
        :is="currentComponent"
        v-if="stars === 0"
        class="review-star inline-block"
        :width="size"
        :height="size"
      />
      <component
        :is="currentComponent"
        v-for="i in parseInt(6 - stars)"
        v-else-if="stars > 0"
        :key="`hss_${stars}_${i}`"
        class="review-star inline-block"
        width="16"
        height="16"
      />
    </template>
    <template v-if="!$isSol">
      <fa
        v-if="stars === 0"
        class="star"
        icon="star"
        :style="{ width: `${size}px` }"
      />
      <fa
        v-for="i in parseInt(6 - stars)"
        v-else-if="stars > 0"
        :key="`hss_${stars}_${i}`"
        icon="star"
      />
    </template>
  </span>
</template>

<script>
export default defineNuxtComponent({
  name: 'StarByLocale',

  props: {
    dark: {
      type: Boolean,
      default: false,
    },

    size: {
      type: Number,
      default: 15,
    },

    stars: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    currentComponent () {
      return this.dark ? 'i-solresor-icon' : 'i-solresor-full'
    },
  },
})
</script>
